


















































































import Vue from "vue";

export default Vue.extend({
  props: {
    course: {
      type: Object,
      required: true
    },
    courseRegistration: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      panel: [0]
    };
  },

  metaInfo: () => {
    return {
      title: "Giriş"
    };
  }
});
